const partnersConfig = {
  insulet: {
    displayActionBarHeader: true,
  },
  emea: {
    displayActionBarHeader: false,
  },
};

export default partnersConfig;
