import { useCallback } from 'react';

import { refreshPage } from 'Utilities/browser';

const useDefaultError = () => {
  const handleInitializationError = useCallback(() => {
    refreshPage();
  }, []);

  return { handleInitializationError };
};

export default useDefaultError;
