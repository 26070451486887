import { useMemo } from 'react';

import { store } from 'Utilities/store';

import partnersConfig from '../config/partnersConfig';
import useEmeaAuth from './partners/emea/useEmeaAuth';
import useInsuletAuth from './partners/insulet/useInsuletAuth';
import useInsuletError from './partners/insulet/useInsuletError';
import useAuth from './useAuth';
import useDefaultError from './useDefaultError';

const hookByApp = {
  insulet: { useAuth: useInsuletAuth, useError: useInsuletError },
  emea: { useAuth: useEmeaAuth, useError: useDefaultError },
  default: { useAuth: useAuth, useError: useDefaultError },
};

const usePartner = () => {
  const {
    env: { app, pId },
    config: { partners },
  } = store.getState();

  const { useAuth, useError } = useMemo(() => hookByApp[app] ?? hookByApp.default, [app]);

  const authHook = useAuth();
  const errorHook = useError();

  const partner = useMemo(() => {
    if (partners) {
      const partner = partners[pId];

      if (partner) {
        return partner;
      }
    }

    return { displayName: '', website: '', type: '' };
  }, [pId, partners]);

  return {
    ...authHook,
    ...errorHook,
    displayActionBarHeader: partnersConfig[app]?.displayActionBarHeader ?? false,
    partner,
  };
};

export default usePartner;
