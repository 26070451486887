export const checkBrowser = () => {
  const isOpera = !!(window as any)['opera'] || window.navigator.userAgent.indexOf(' OPR/') >= 0;

  return {
    isChrome: !!(window as any)['chrome'] && !isOpera,
    isEdge: /Edge\/\d+/.test(window.navigator.userAgent),
    isFirefox: typeof (window as any)['InstallTrigger'] !== 'undefined',
    isIE: !!(document as any)['documentMode'], // At least IE6
    isOpera: isOpera,
    isSafari:
      navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1,
    isIPhone: navigator.userAgent.indexOf('iPhone') !== -1,
    isAndroid: /Android/i.test(navigator.userAgent),
  };
};

export const redirect = (redirectUrl: string) => {
  window.location.replace(redirectUrl);
};

export const refreshPage = () => {
  window.location.reload();
};
