import { useCallback } from 'react';

import { InsuletStatusCode } from 'Enums';

import { redirect } from 'Utilities/browser';
import { store } from 'Utilities/store';

const useInsuletError = () => {
  const redirectOnError = useCallback((redirectUrl: string, statusCode: number) => {
    if (redirectUrl) {
      const separator = redirectUrl.includes('?') ? '&' : '?';

      const url = `${redirectUrl}${separator}errorcode=${statusCode}`;

      redirect(url);
    }
  }, []);

  const handleInitializationError = useCallback(() => {
    const redirectUrl = store.getState().env.redirect_uri;

    redirectOnError(redirectUrl, InsuletStatusCode.INTERNAL_ERROR);
  }, [redirectOnError]);

  return { handleInitializationError, redirectOnError };
};

export default useInsuletError;
