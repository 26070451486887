import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Text } from '@adc/parallax-component-library';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ApiStatus } from 'Enums';

import { useAccount, useApiError } from 'Hooks';

import {
  Button,
  Form,
  Header,
  InputPassword,
  Main,
  PasswordInputValidator,
  ResponsiveContainer,
  Spinner,
} from 'Components/utility';

import i18n from 'Utilities/i18n';

import { resetPass } from 'Services/auth';

import { RootState } from 'src/reducers';

interface MyFormValues {
  newPassword: string;
  repeatNewPassword: string;
}

const initialValues: MyFormValues = {
  newPassword: '',
  repeatNewPassword: '',
};

const mapStateToProps = ({
  config: { validation },
  nav: {
    query: { email, token, app },
  },
}: RootState) => {
  return {
    validation,
    email,
    token,
    app,
    leadingTrailingSpacesIdentity: validation?.identity.find(
      (identity) => identity.data.key === 'leadingTrailingSpaces'
    ),
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
  next: () => void;
  handleTokenExpirationError: () => void;
};

const ResetPasswordFirstStep: React.FC<Props> = ({
  validation,
  email,
  token,
  app,
  next,
  handleTokenExpirationError,
  leadingTrailingSpacesIdentity,
}) => {
  const [meetsPasswordRequirements, setMeetsPasswordRequirements] = useState(false);

  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const { showPasswordValidationError } = useAccount();

  const validationSchema = Yup.object().shape({
    newPassword: leadingTrailingSpacesIdentity
      ? Yup.string().matches(
          new RegExp(leadingTrailingSpacesIdentity.data.regex),
          i18n.t<string>(
            `Login.content.signInForm.formField.password.helpText.${leadingTrailingSpacesIdentity.data.key}`
          )
        )
      : Yup.string(),
    repeatNewPassword: Yup.string()
      .required(
        i18n.t<string>(
          'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.errors.passwordMustMatch'
        )
      )
      .oneOf(
        [Yup.ref('newPassword')],
        i18n.t<string>(
          'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.errors.passwordMustMatch'
        )
      ),
  });

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    handleChange,
    isValid,
    errors,
    touched,
    values,
    setTouched,
    setErrors,
  } = useFormik({
    initialValues,
    onSubmit: onSubmitForm,
    validationSchema,
    validateOnBlur: true,
  });

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.BAD_REQUEST: {
          const data = error.response?.data;

          if (data && data.code === ApiStatus.PASSWORD_VALIDATION) {
            setFieldValue('newPassword', '');
            setFieldValue('repeatNewPassword', '');

            setTouched({});
            setErrors({});

            showPasswordValidationError({ validation }, data.errors);
            return;
          }

          showApiErrorModal();

          break;
        }

        case ApiStatus.UNAUTHORIZED: {
          handleTokenExpirationError();
          break;
        }

        default:
          showApiErrorModal();
      }
    },
    [
      setFieldValue,
      setTouched,
      setErrors,
      showApiErrorModal,
      showPasswordValidationError,
      handleTokenExpirationError,
      validation,
    ]
  );

  function onSubmitForm() {
    setLoading(true);
    resetPass(values.newPassword, token, app)
      .then(next)
      .catch(handleApiRequestError)
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Main>
      {loading && <Spinner />}
      <img
        src={i18n.t<string>('Global.microcopy.common.libreLogo')}
        alt=""
        id="Global.microcopy.common.libreLogo"
        style={{
          position: 'absolute',
          zIndex: 1,
          marginTop: '20px',
          width: '100px',
          height: '50px',
          objectFit: 'contain',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <ResponsiveContainer>
        <Header
          titleTestID="ResetPassword.title"
          title={i18n.t<string>('ResetPassword.title')}
          textAlign="center"
        />
        <Text
          marginTop="$2"
          testID="ResetPassword.subtitle"
          fontSize="$bodyBase.default"
          fontWeight="$bodyBase.default"
          color="$text.80"
          textAlign="center"
        >
          {i18n.t<string>('ResetPassword.subtitle')}
        </Text>
        <Text
          testID="ResetPassword.title-email"
          fontSize="$bodyBase.default"
          fontWeight="$bodyBase.default"
          color="$text.80"
          textAlign="center"
        >
          {email}
        </Text>
        <Form style={{ padding: 0, marginTop: 16 }}>
          <PasswordInputValidator
            placeholder={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.newPassword.placeholder'
            )}
            label={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.newPassword.label'
            )}
            onChangeText={handleChange('newPassword')}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur('newPassword')}
            password={values.newPassword}
            onValidate={setMeetsPasswordRequirements}
            identities={validation?.identity}
            testID="newPassword"
          />
          <InputPassword
            mt={1}
            mb={5}
            label={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.label'
            )}
            placeholder={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.placeholder'
            )}
            testID="repeatNewPassword"
            onBlur={handleBlur('repeatNewPassword')}
            setFieldValue={setFieldValue}
            onChangeText={handleChange('repeatNewPassword')}
            value={values.repeatNewPassword}
            isInvalid={errors.repeatNewPassword && touched.repeatNewPassword ? true : false}
            errorMessage={errors.repeatNewPassword}
          />
          <Button
            testID="ResetPassword.button"
            marginTop="$4"
            onPress={() => handleSubmit()}
            disabled={
              !values.newPassword ||
              !values.repeatNewPassword ||
              !isValid ||
              !meetsPasswordRequirements
            }
            label={i18n.t<string>('ResetPassword.title')}
            aria-label={i18n.t<string>('ResetPassword.title')}
          />
        </Form>
      </ResponsiveContainer>
    </Main>
  );
};

export default connector(ResetPasswordFirstStep);
