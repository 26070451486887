import { useCallback } from 'react';

import { redirect } from 'Utilities/browser';
import { store } from 'Utilities/store';

import { getLegacyToken, signIn } from 'Services/auth';

import useAuth from '../../useAuth';

const useEmeaAuth = () => {
  const {
    handleLoginError,
    handleLockoutError,
    handleForgotPasswordDone,
    handleResetPasswordDone,
    handleCountryRegionError,
    handleUnexpectedError,
    showHcpError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    showTokenExpirationError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  } = useAuth();

  const authenticateUser = useCallback(async (email: string, password: string) => {
    const { app, pId } = store.getState().env;
    const { sharingWebviewsUrl } = store.getState().config;

    if (!sharingWebviewsUrl) {
      return;
    }

    const { accessToken, accountId } = await signIn(email, password, app);

    const legacyToken = await getLegacyToken(app, accountId, accessToken);

    const legacyConnectedAppsUrl = `${sharingWebviewsUrl}?token=${legacyToken}&pId=${pId}`;

    redirect(legacyConnectedAppsUrl);
  }, []);

  return {
    authenticateUser,
    handleLoginError,
    handleLockoutError,
    handleCountryRegionError,
    handleForgotPasswordDone,
    handleResetPasswordDone,
    handleUnexpectedError,
    showHcpError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    showTokenExpirationError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  };
};

export default useEmeaAuth;
