import React, { memo, useCallback, useMemo, useState } from 'react';

import { InputErrorField, InputPassword, PasswordRequirements } from 'Components/utility';

type Props = {
  placeholder: string;
  label: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: (e: any) => void;
  onChangeText: (e: any) => void;
  password: string;
  onValidate?: (meetsPasswordRequirements: boolean) => void;
  identities?: Identity[];
  testID: string;
  errors: any;
  touched: any;
};

const PasswordInputValidator = memo(
  ({
    placeholder,
    label,
    setFieldValue,
    handleBlur,
    onChangeText,
    password,
    onValidate,
    errors,
    touched,
    identities = [],
    testID,
  }: Props) => {
    const [isBlurred, setIsBlurred] = useState(false);
    const [meetsPasswordRequirements, setMeetsPasswordRequirements] = useState(false);

    const onFocus = useCallback(() => {
      setIsBlurred(false);
    }, []);

    const filteredIdentities = useMemo(() => {
      const keysToFilter = ['min'];

      return identities.filter(({ data: { key } }) => keysToFilter.includes(key));
    }, [identities]);

    const requirements: Requirement[] = useMemo(() => {
      return filteredIdentities.map(({ data, ...rest }) => {
        const match = new RegExp(data.regex).test(password.trim());

        const isInvalid = touched?.[testID] && isBlurred && !match;

        return { match, isInvalid, data, ...rest };
      });
    }, [password, filteredIdentities, isBlurred, touched, testID]);

    const validatePassword = useCallback(() => {
      const remainingRequirements = requirements.filter((requirement) => !requirement.match);

      const meetsPasswordRequirements = !remainingRequirements.length;

      setMeetsPasswordRequirements(meetsPasswordRequirements);

      onValidate && onValidate(meetsPasswordRequirements);
    }, [requirements, onValidate]);

    const onBlur = useCallback(
      (e: any) => {
        setIsBlurred(true);
        validatePassword();
        handleBlur(e);
      },
      [handleBlur, validatePassword]
    );

    return (
      <>
        <InputPassword
          isInvalid={
            (!meetsPasswordRequirements && isBlurred) ||
            (errors?.[testID] && touched?.[testID] ? true : false)
          }
          placeholder={placeholder}
          label={label}
          testID={testID}
          setFieldValue={setFieldValue}
          onBlur={onBlur}
          onChangeText={onChangeText}
          value={password}
          onFocus={onFocus}
        />
        <PasswordRequirements requirements={requirements} />
        {errors?.[testID] && touched?.[testID] && (
          <InputErrorField errorMessage={errors?.[testID]} testID={testID} />
        )}
      </>
    );
  }
);

PasswordInputValidator.displayName = 'PasswordInputValidator';

export { PasswordInputValidator };
