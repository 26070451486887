import React, { useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { usePartner } from 'Hooks';

import { LogoActionBar, Main, Spinner } from 'Components/utility';

import { RootState } from 'Reducers/index';

import { redirect } from 'Utilities/browser';
import mediator from 'Utilities/mediator';
import { getAuthSession } from 'Utilities/session';

import { createConnection } from 'Services/insulet';

const mapStateToProps = ({ env: { redirect_uri } }: RootState) => {
  return {
    redirect_uri,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const Connection: React.FC<Props> = ({ redirect_uri }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { handleUnexpectedError } = usePartner();

  useEffect(() => {
    const initializeConnection = async () => {
      try {
        setIsLoading(true);

        const authSession = getAuthSession<InsuletAuthSession>();

        if (authSession) {
          const { cid, accountId, accessToken } = authSession;

          const redirectUrl = await createConnection(cid, redirect_uri, accountId, accessToken);

          redirect(redirectUrl);

          return;
        }

        handleUnexpectedError();
      } catch (err) {
        handleUnexpectedError();
      } finally {
        setIsLoading(false);
      }
    };

    initializeConnection();
  }, [handleUnexpectedError, redirect_uri]);

  return (
    <Main>
      <LogoActionBar testID="Connection" />
      {isLoading && <Spinner testID="Connection" />}
    </Main>
  );
};

export default connector(Connection);
