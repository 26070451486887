import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { VStack } from '@adc/parallax-component-library';

import { useApiError } from 'Hooks';

import {
  Button,
  Footer,
  Header,
  LogoActionBar,
  Main,
  RichText,
  ScrollContainer,
  Spinner,
} from 'Components/utility';

import { RootState } from 'Reducers/index';
import { REGISTER_CLEAR } from 'Reducers/register/types';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { getAuthSession } from 'Utilities/session';
import { store } from 'Utilities/store';

import { updateConsent } from 'Services/consent';

const mapStateToProps = ({ env: { app }, register }: RootState) => {
  return {
    app,
    patientId: register.patientId,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const RWEConsent: React.FC<Props> = ({ app, patientId }) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const handleSubmitResearchAction = useCallback(
    async (action: string) => {
      try {
        setLoading(true);

        const authSession = getAuthSession<AuthSession>();

        if (authSession) {
          await updateConsent(patientId, action, app, 'realWorldEvidence', authSession.accessToken);
          mediator.publish('router:navigate', '/login');
          store.dispatch({ type: REGISTER_CLEAR });
          return;
        }

        showApiErrorModal();
      } catch {
        showApiErrorModal();
      } finally {
        setLoading(false);
      }
    },
    [app, patientId, showApiErrorModal]
  );

  const handleLinkClick = (href: string) => {
    mediator.publish('router:navigate', href);
  };

  return (
    <Main>
      <LogoActionBar
        titleTestID="Research.actionTitle"
        title={i18n.t<string>('Research.title')}
        noActionButton
      />
      {loading && <Spinner />}
      <ScrollContainer>
        <Header testID="Research.title" title={i18n.t<string>('Research.title')} />
        <VStack padding="$4">
          <RichText
            onPressLink={handleLinkClick}
            text={i18n.t<string>('Research.content.researchRichText.bodyText')}
          />
        </VStack>
      </ScrollContainer>
      <Footer
        customProps={
          <VStack padding="$4">
            <Button
              onPress={() => handleSubmitResearchAction('accept')}
              testID="RWE.optIn"
              label={i18n.t<string>('Global.microcopy.common.optIn')}
              aria-label={i18n.t<string>('Global.microcopy.common.optIn')}
              marginBottom="$4"
            />
            <Button
              variant="tertiary"
              testID="Global.microcopy.common.decline"
              label={i18n.t<string>('Global.microcopy.common.decline')}
              aria-label={i18n.t<string>('Global.microcopy.common.decline')}
              onPress={() => handleSubmitResearchAction('decline')}
            />
          </VStack>
        }
      />
    </Main>
  );
};

export default connector(RWEConsent);
