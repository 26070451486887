import React, { memo, useEffect, useRef, useState } from 'react';
import Animated, { useAnimatedScrollHandler } from 'react-native-reanimated';
import { ConnectedProps, connect } from 'react-redux';

import { IStackProps, Stack } from 'native-base';

import { RootState } from 'Reducers/index';
import { SCROLL_ACTIVE, SCROLL_STATUS } from 'Reducers/scrollPage/types';

import { scrolledTopOrBottom } from 'Utilities/newyu';
import { store } from 'Utilities/store';

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & IStackProps;

const ScrollContainer = ({ children, scrollPage }: Props) => {
  const scrollRef = useRef<any>();
  const childrenRef = useRef<any>();

  const [isScrollActive, setIsScrollActive] = useState(false);

  useEffect(() => {
    store.dispatch({ type: SCROLL_ACTIVE, isActive: isScrollActive });

    if (!isScrollActive) {
      scrollRef.current?.scrollTo({ top: 0 });
    }
  }, [isScrollActive]);

  useEffect(() => {
    if (childrenRef.current && scrollRef.current) {
      const mainHeight = scrollPage.mainSize ? scrollPage.mainSize : window.innerHeight;

      if (
        childrenRef.current.clientHeight >
        mainHeight - scrollPage.footerSize - scrollPage.actionBarSize
      ) {
        setIsScrollActive(true);
      }

      let lastChildPaddingBottom = 0;

      // Get last child element padding bottom size.
      if (childrenRef.current.lastChild?.style.paddingBottom) {
        const paddingBottom = childrenRef.current.lastChild.style.paddingBottom as string;

        lastChildPaddingBottom = Number(paddingBottom.replace('px', '').trim());
      }

      // Verify if the child element has resized, if so, enable or disable the scroll.
      const resizeObserver = new ResizeObserver(() => {
        if (childrenRef.current) {
          const hasIncreasedChildElementSize =
            childrenRef.current.clientHeight >
            mainHeight -
              (scrollPage.footerSize - lastChildPaddingBottom) -
              scrollPage.actionBarSize;

          setIsScrollActive(hasIncreasedChildElementSize);
        }
      });

      resizeObserver.observe(childrenRef.current);

      return () => resizeObserver.disconnect();
    }
  }, [scrollPage.actionBarSize, scrollPage.footerSize, scrollPage.mainSize]);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      if (!scrollRef.current) {
        return;
      }

      const scrollBottomedOut = scrolledTopOrBottom(scrollRef.current).bottom;

      store.dispatch({
        type: SCROLL_STATUS,
        status: { scrollValue: e.contentOffset.y, bottomedOut: scrollBottomedOut },
      });
    },
  });

  return (
    <>
      <Animated.ScrollView
        style={{ marginTop: scrollPage.actionBarSize }}
        ref={scrollRef}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
      >
        <Stack ref={childrenRef}>{children}</Stack>
      </Animated.ScrollView>
    </>
  );
};

const ConnectedScrollContainer = connector(memo(ScrollContainer));

export { ConnectedScrollContainer as ScrollContainer };
