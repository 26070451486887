import React, { useEffect, useRef } from 'react';
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { ConnectedProps, connect } from 'react-redux';

import { HStack, Stack, Text, VStack } from '@adc/parallax-component-library';
import { IconBack } from '@adc/parallax-icons';

import { IconButton } from 'Components/utility';

import { ACTION_BAR_SIZE } from 'Reducers/scrollPage/types';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

import { RootState } from 'src/reducers';

import { ActionBarHeader } from './ActionBarHeader';

type LogoActionBarProps = {
  titleTestID?: string;
  actionButtonTestID?: string;
  testID?: string;
  title?: string;
  onBack?: () => void;
  noActionButton?: boolean;
};

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & LogoActionBarProps;

const LogoActionBar: React.FC<Props> = ({
  titleTestID,
  actionButtonTestID,
  scrollPage,
  title,
  onBack,
  noActionButton,
}) => {
  const actionBarRef = useRef<any>();

  useEffect(() => {
    if (actionBarRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (actionBarRef.current) {
          store.dispatch({
            type: ACTION_BAR_SIZE,
            actionBarSize: actionBarRef.current.clientHeight,
          });
        }
      });

      resizeObserver.observe(actionBarRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  const logoAnimatedStyles = useAnimatedStyle(() => {
    if (!scrollPage.isActive) {
      return {
        opacity: 1,
      };
    }

    const opacity = interpolate(scrollPage.scrollValue, [0, 20], [1, 0], Extrapolation.CLAMP);

    return {
      opacity,
    };
  });

  const titleAnimatedStyles = useAnimatedStyle(() => {
    if (!scrollPage.isActive) {
      return {
        opacity: 0,
      };
    }

    const opacity = interpolate(scrollPage.scrollValue, [0, 20], [0, 1], Extrapolation.CLAMP);

    return {
      opacity,
    };
  });

  return (
    <VStack
      ref={actionBarRef}
      style={{
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 2,
      }}
      backgroundColor="$surface.container"
    >
      <ActionBarHeader />
      <HStack padding="$4" justifyContent="center" alignItems="center">
        {!noActionButton && (
          <IconButton
            marginLeft="$4"
            style={{ position: 'absolute', zIndex: 2, left: 0, cursor: 'pointer' }}
            onPress={() => (onBack ? onBack() : mediator.publish('router:back'))}
            testID={actionButtonTestID}
          >
            <IconBack testID={`${actionButtonTestID}-icon`} />
          </IconButton>
        )}
        <Animated.View style={logoAnimatedStyles}>
          <img src={i18n.t('Global.microcopy.common.libreLogo')} alt="" id="Global.libreLogo" />
        </Animated.View>
        {title && (
          <Animated.View
            style={[
              {
                position: 'absolute',
                zIndex: 1,
                width: '100%',
              },
              titleAnimatedStyles,
            ]}
          >
            <Text
              testID={titleTestID}
              color="$text.100"
              fontSize="$h4.strong"
              fontWeight="$h4.strong"
              alignSelf="center"
              textAlign="center"
              marginHorizontal="$5"
              maxWidth="70%"
            >
              {title}
            </Text>
          </Animated.View>
        )}
        <Stack />
      </HStack>
    </VStack>
  );
};

const ConnectedLogoActionBar = connector(LogoActionBar);

export { ConnectedLogoActionBar as LogoActionBar };
